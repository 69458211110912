export const supportGlobal = ['status_tags', 'interests']

export const supportMatch = [
  'work_offer_statuses',
  'everest_developer_types',
  'admission_members',
  'developer_types',
  'salary_ranges',
  'trs_reviewer_work_offer',
  'services_tokens',
  'work_offer_urgencies',
  'mentor_sales_executives',
  'reasons_for_incomplete_closed_offer',
  'perks',
  'project_complexities',
  'mentor_partnerships',
  'job_titles',
  'contract_types',
  'expertise_levels',
  'work_modalities',
  'services',
  'skills',
  'reasons_to_disable_work_offer',
  'services_subscriptions',
  'services_upsells',
]

export const supportSales = [
  'services_tokens',
  'reasons_for_lost_of_business_prospect',
  'businesses_prospects_owners',
  'basic_languages',
  'mentor_sales_executives',
  'businesses_prospects_statuses',
  'businesses_prospects_sources',
  'commercial_ratings',
  'pricing_models',
  'services',
  'services_subscriptions',
  'services_upsells',
  'coupons',
  'fees',
  'services_offers',
]

export const supportMentor = [
  'associate_owner_members',
  'applicant_initiatives',
  'developer_types',
  'pending_deliverables_statuses',
  'pending_revisions_user_statuses',
  'pending_revisions',
  'retired_reasons',
  'employment_factors',
  'expertise_levels',
  'user_statuses',
]

export const supportCustomerSuccess = [
  'aic_statuses',
  'user_deliverables_review_statuses',
  'user_onboarding_statuses',
  'success_fee_payments_status_list',
  'retired_reasons',
]

export const supportAdmission = [
  'scholarship_types',
  'admission_members',
  'applicant_statuses',
  'applicant_initiatives',
  'developer_types',
  'company_sizes',
  'employment_factors',
  'genres',
  'skills',
]

export const supportCollection = [
  'scholarship_types',
  'admission_members',
  'applicant_initiatives',
  'user_statuses',
]
