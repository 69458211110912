const initialState = {
  search: {
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      total: 0,
    },
  },
  querySearch: {},
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_SEARCH(state, { type, value }) {
    state[type] = value
  },
  DELETE_ELEMENT(state, id) {
    const indexDelete = state.search.data.findIndex((item) => item.id === id)

    if (indexDelete >= 0) {
      state.search.data.splice(indexDelete, 1)
    }
  },
}

export const actions = {
  async fetchSearchKanban({ commit }, payload) {
    const { offerId, params } = payload

    const response =
      await this.$repository.v4.partnership.offers.getOffersColumnKanban(
        offerId,
        params
      )

    if (!response.success) {
      commit('SET_STATE_SEARCH', {
        type: 'search',
        value: { ...initialState.search },
      })
    }

    if (response.success) {
      const dataSearch = {
        data: response.result.data,
        pagination: response.result.paginate,
      }

      commit('SET_STATE_SEARCH', {
        type: 'search',
        value: dataSearch,
      })
    }

    return response
  },
}
