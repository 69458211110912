import authRepository from './auth'
import webhooksRepository from './webhooks'
import admissionsRepository from './admissions'

// MENTOR
import mentorReportsRepository from './mentor/reports'
import mentorProfileRepository from './mentor/profile'
import mentorApplicantsRepository from './mentor/applicants'
import mentorUserProfileRepository from './mentor/userProfile'
import mentorApplicationRepository from './mentor/application'
import mentorNotesRepository from './mentor/notesRepository'

// MENTOR-V2
import mentorV2HomeRepository from './mentor/v2/home'
import mentorV2SupportRepository from './mentor/v2/support'
import mentorV2UserProfileRepository from './mentor/v2/userProfile'
import mentorV2DeliverablesRepository from './mentor/v2/deliverables'

// V1
import v1OutreachTableRepository from './v1/outreach/table'
import v1OutreachUserProfileRepository from './v1/outreach/userProfile'

// V2
import v2SupportRepository from './v2/support'

// V3
import v3SupportRepository from './v3/support'
import v3CustomerSuccessRepository from './v3/customerSuccess'
import v3coursesRepository from './v3/courses'
import v3LeadRepository from './v3/lead'

// V3-ADMIN
import v3AdminModulesRepository from './v3/admin/modules'
import v3AdminMissionsRepository from './v3/admin/missions'
import v3AdminObjectivesRepository from './v3/admin/objectives'
import v3AdminObjectiveTypesRepository from './v3/admin/objectiveTypes'
import v3AdminCalendarSessionRepository from './v3/admin/calendarSession'

// V3-ADMISSION
import v3AdmissionApplicantRepository from './v3/admission/applicant'
import v3AdmissionApplicantsRepository from './v3/admission/applicants'
import v3AdmissionApplicantRejectionCategoriesRepository from './v3/admission/applicants/rejectionCategories'
import v3AdmissionFollowUpRepository from './v3/admission/applicants/followUp'
import v3AdmissionActiveProcessesRepository from './v3/admission/applicants/activeProcesses'
import v3AdmissionMatchUserRepository from './v3/admission/matchUserRepository'

// V3-COLLECTION
import v3CollectionUserRepository from './v3/collection/user'
import v3CollectionRepository from './v3/collection/collection'
import v3CollectionContractRepository from './v3/collection/contract'

// V3-CUSTOMER-SUCCESS
import v3CustomerSuccessUsersRepository from './v3/customer-success/user'
import v3CustomerSuccessFeeRepository from './v3/customer-success/successFee'

// V3-LEADS
import v3InterestingLeadsRepository from './v3/interesting-leads/interestingLeads'

// V3-MENTOR
import v3CustomLearningPath from './v3/mentor/customLearningPath'
import v3MentorWeeksRepository from './v3/mentor/weeks'
import v3MentorMentorRepository from './v3/mentor/mentor'
import v3MentorMentorsRepository from './v3/mentor/mentors'
import v3MentorTalentNotesRepository from './v3/mentor/talentNotes'
import v3MentorGroupSessionsRepository from './v3/mentor/groupSessions'
import v3MentorDeliverablesRepository from './v3/mentor/deliverables'
import v3MentorUserDeliverablesRepository from './v3/mentor/userDeliverables'
import v3MentorGroupSessionNotesRepository from './v3/mentor/groupSessionNotes'
import jobSourcingRepository from './v3/mentor/jobSourcingRepository'

// V3-PARTNERSHIP
import v3PartnershipWorkOffersRepository from './v3/partnership/workOffers/workOffers'
import v3PartnershipMatchUserRepository from './v3/partnership/matchUser'
import v3PartnershipBusinessesRepository from './v3/partnership/businesses'
import v3PartnershipMatchUserNotesRepository from './v3/partnership/matchUser/notesRepository'
import businessUserRepository from './v3/partnership/business/businessUserRepository'

import v3PartnershipWorkOffersKanbanRepository from './v3/partnership/workOffers/workOffersKanban'

// V4-SALES
import v4PartnershipMatchUserNotesRepository from './v4/partnership/matchUser/notesRepository'
import v4PartnershipMatchUserRepository from './v4/partnership/matchUser'
import v4PartnershipWorkOffersRepository from './v4/partnership/workOffers/workOffers'
import v4PartnershipWorkOffersKanbanRepository from './v4/partnership/workOffers/workOffersKanban'
import V4SalesBusinesses from '~/repositories/v4/sales/businesses'
import V4SalesCoupons from '~/repositories/v4/sales/coupons'
import V4SalesBusinessProspects from '~/repositories/v4/business-sales/businessProspects'
import v4PartnershipBusinessRepository from '~/repositories/v4/partnership/business'
import v4EverestSkills from '~/repositories/v4/everest/skills'
import v4EverestRoles from '~/repositories/v4/everest/roles'

let useFetch = null

export default ($axios) => {
  useFetch = $axios

  return {
    auth: authRepository($axios),
    webhooks: webhooksRepository($axios),
    admissions: admissionsRepository($axios),
    mentor: {
      reports: mentorReportsRepository($axios),
      profile: mentorProfileRepository($axios),
      applicants: mentorApplicantsRepository($axios),
      application: mentorApplicationRepository($axios),
      userProfile: mentorUserProfileRepository($axios),
      notes: mentorNotesRepository($axios),
      v2: {
        home: mentorV2HomeRepository($axios),
        support: mentorV2SupportRepository($axios),
        userProfile: mentorV2UserProfileRepository($axios),
        deliverables: mentorV2DeliverablesRepository($axios),
      },
    },

    // Versions
    v1: {
      outreach: {
        table: v1OutreachTableRepository($axios),
        userProfile: v1OutreachUserProfileRepository($axios),
      },
    },
    v2: {
      support: v2SupportRepository($axios),
    },
    v3: {
      leads: v3LeadRepository($axios),
      courses: v3coursesRepository($axios),
      support: v3SupportRepository($axios),
      customerSuccessV3: v3CustomerSuccessRepository($axios),
      admin: {
        modules: v3AdminModulesRepository($axios),
        missions: v3AdminMissionsRepository($axios),
        objectives: v3AdminObjectivesRepository($axios),
        objectiveTypes: v3AdminObjectiveTypesRepository($axios),
        calendarSession: v3AdminCalendarSessionRepository($axios),
      },
      admission: {
        applicant: v3AdmissionApplicantRepository($axios),
        matchUsers: v3AdmissionMatchUserRepository($axios),
        applicants: {
          ...v3AdmissionApplicantsRepository($axios),
          followUp: v3AdmissionFollowUpRepository($axios),
          activeProcesses: v3AdmissionActiveProcessesRepository($axios),
          rejectionCategories:
            v3AdmissionApplicantRejectionCategoriesRepository($axios),
        },
      },
      collection: {
        ...v3CollectionRepository($axios),
        user: v3CollectionUserRepository($axios),
        contract: v3CollectionContractRepository($axios),
      },
      customerSuccess: {
        user: v3CustomerSuccessUsersRepository($axios),
        successFee: v3CustomerSuccessFeeRepository($axios),
      },
      interestingLeads: {
        leads: v3InterestingLeadsRepository($axios),
      },
      mentor: {
        ...v3MentorMentorRepository($axios),
        weeks: v3MentorWeeksRepository($axios),
        mentors: v3MentorMentorsRepository($axios),
        notes: v3MentorTalentNotesRepository($axios),
        groupSessions: v3MentorGroupSessionsRepository($axios),
        deliverables: v3MentorDeliverablesRepository($axios),
        userDeliverables: v3MentorUserDeliverablesRepository($axios),
        groupSessionNotes: v3MentorGroupSessionNotesRepository($axios),
        customLearningPath: v3CustomLearningPath($axios),
        jobSourcing: jobSourcingRepository($axios),
      },
      partnership: {
        offers: v3PartnershipWorkOffersRepository($axios),
        offersKanban: v3PartnershipWorkOffersKanbanRepository($axios),
        businesses: v3PartnershipBusinessesRepository($axios),
        matchUser: v3PartnershipMatchUserRepository($axios),
        notes: v3PartnershipMatchUserNotesRepository($axios),
        businessUser: businessUserRepository($axios),
      },
    },
    v4: {
      everest: {
        skills: v4EverestSkills($axios),
        roles: v4EverestRoles($axios),
      },
      sales: {
        businesses: V4SalesBusinesses($axios),
        coupons: V4SalesCoupons($axios),
      },
      businessSales: {
        businessProspects: V4SalesBusinessProspects($axios),
      },
      partnership: {
        notes: v4PartnershipMatchUserNotesRepository($axios),
        matchUser: v4PartnershipMatchUserRepository($axios),
        business: v4PartnershipBusinessRepository($axios),
        offers: v4PartnershipWorkOffersRepository($axios),
        offersKanban: v4PartnershipWorkOffersKanbanRepository($axios),
      },
    },
  }
}

export function useRequestOffice(method, url, params) {
  return useFetch[method](url, params)
    .then(({ data }) => data)
    .catch(({ data }) => data)
}
